import React, { useState, useEffect } from "react";

  // Firebase App (the core Firebase SDK) is always required and must be listed first
  import * as firebase from "firebase/app";
  import "firebase/firestore";
  import 'firebase/analytics';
  import 'firebase/auth'

import Library from "./Library.js";

// https://github.com/mui-org/material-ui
// TODO: CssBaseline. https://material-ui.com/components/css-baseline/
// TODO: React from CDN? https://reactjs.org/docs/cdn-links.html
// TODO: https://vercel.com/gabedottl/csb-b1mnl/krfotzmfy
// TODO: https://reactjs.org/docs/add-react-to-a-website.html
function App() {
  // const books = [
  //   {'name': 'Anatomy of Peace', 'url': 'http://covers.openlibrary.org/b/isbn/1626564310-L.jpg', 'amazon': 'https://amzn.to/2HFZyqg'},
  //   {'name': 'Albert Einstein: Creator and Rebel', 'url': 'http://covers.openlibrary.org/b/isbn/058608195X-L.jpg', 'amazon': 'https://amzn.to/2S4l8qq'},
  //   {'name': 'A Full Life: Reflections at Ninety', 'url': 'http://covers.openlibrary.org/b/isbn/1501115642-L.jpg', 'amazon': 'https://amzn.to/2EFjyIi'}];
  const [books, setBooks] = useState([]);
  // Following example: https://reactjs.org/docs/faq-ajax.html
  useEffect(() => {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    // https://console.firebase.google.com/u/4/project/mylibrary-46202/settings/general/web:NjY5YzEwMjEtMzk1Zi00YThiLWFiZGMtOGMxNjdkNTA4ZjUy
    var firebaseConfig = {
      apiKey: "AIzaSyBvm6pL9LUOt_ifSOxThSd5jVNRRK6LlNs",
      authDomain: "mylibrary-46202.firebaseapp.com",
      databaseURL: "https://mylibrary-46202.firebaseio.com",
      projectId: "mylibrary-46202",
      storageBucket: "mylibrary-46202.appspot.com",
      messagingSenderId: "649690647371",
      appId: "1:649690647371:web:93ac666d697ed25f9ff6ce",
      measurementId: "G-VTHP1FEFXR"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    const db = firebase.firestore();
    const doc_ref = db.collection('users').doc('gdottl');
    doc_ref.get().then((doc) => {
      // https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentSnapshot
      const result = doc.data()['books'];
      console.log(result);
      let books = [];
      for (const [asin, book] of Object.entries(result)) {
        books.push(book);
      }
      console.log(books);
      setBooks(books);
    }).catch(err => console.log);
  }, []);

  console.log(books);
  return <Library books={books} />;
}

export default App;
