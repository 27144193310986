
// "@typescript-eslint/eslint-plugin": "^2.34.0",
// "@typescript-eslint/parser": "^2.34.0",
// "babel-eslint": "^10.1.0",
// "eslint": "^7.9.0",
// "eslint-config-google": "^0.14.0",
// "eslint-config-react-app": "^5.2.1",
// "eslint-plugin-flowtype": "^4.7.0",
// "eslint-plugin-import": "^2.22.0",
// "eslint-plugin-jsx-a11y": "^6.3.1",
// "eslint-plugin-react": "^7.21.1",
// "eslint-plugin-react-hooks": "^2.5.1"

import React, {useState} from 'react';
import classNames from 'classnames';

import './Library.css'

// import ReactDOM from "react-dom";
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactResizeDetector from 'react-resize-detector';
import {render} from '@testing-library/react';
// import {Checkbox} from '@material-ui/core';

const BOOK_WIDTH = 120;
const BOOK_SPACE = BOOK_WIDTH / 2;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#999',
  },
  paper: {
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#333',
    width: 120, // Based on Apple Books style.
    // minWidth: 60,
  },
}));

const BASE_ELEVATION = 3;
const HOVER_ELEVATION = 10;

function Book(props) {
  const classes = useStyles();
  const [elevation, setElevation] = useState(BASE_ELEVATION);
  const [hovered, setHovered] = useState(false);

  const footer = React.createRef();
  function onHover(event, hovered) {
    setElevation(hovered ? HOVER_ELEVATION : BASE_ELEVATION);
    setHovered(hovered);
  }
  const footerClass = classNames({
    'book-footer': true,
    'hover': hovered,
  });

  return (
    // <Grid item> ensures paper takes up space within surrounding container
    // 'item' to ensure takes up space within surrounding container; 'container' so that we can use 'justify' to center paper (otherwise, left-aligns).
    <div className="book" onMouseEnter={(e) =>onHover(e, true)} onMouseLeave={(e) =>onHover(e, false)}>
      <Paper elevation={elevation} className={classes.paper}>
        {/* http://blog.matthewcheok.com/a-better-img-tag/ */}
        <div >
          <img
            className="book-img"
            src={props.book.cover_url}
            alt={props.book.name}
          />
          <div className={footerClass} ref={footer}><a href={props.book.amazon} className='buy-link'>Buy</a></div>
        </div>
      </Paper>
    </div>
  );
}

class Library extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }
  componentDidMount() {
    const height = document.getElementById('root').clientHeight;
    this.setState({height: height});
  }

  render() {
    const books = this.props.books.map((book) =>
      <Book key={book.name} book={book}/>,
    );
    return (
    // https://www.npmjs.com/package/react-resize-detector
      <ReactResizeDetector handleWidth handleHeight>
        {({width, height}) => (
          // https://www.freecodecamp.org/news/even-more-about-how-flexbox-works-explained-in-big-colorful-animated-gifs-a5a74812b053/#:~:text=Flex%2Dshrink%20is%20the%20opposite,which%20items%20you%20don't.
          // https://www.freecodecamp.org/news/an-animated-guide-to-flexbox-d280cf6afc35/#.xdqa0my2e
          <div className='library grey' display='flex'>
            {books}
          </div>
        )}
      </ReactResizeDetector>
      // {`${width}x${height}`}
    );
  }
}

export default Library;